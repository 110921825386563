import React from "react";
const Check = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#117025",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="13361"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M256 426.666667l-85.333333 85.333333 256 256L853.333333 341.333333l-85.333333-85.333333-341.333333 341.333333z"
      fill={color}
      p-id="13362"
    ></path>
  </svg>
);

export default Check;
