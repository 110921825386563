import React from "react";
const Truck = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#333",
  ...props
}) => (
  <svg
    viewBox="0 0 1280 1024"
    p-id="23577"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M1264 768h-48V551.8c0-33.6-13.6-66.6-37.6-90.4l-167.8-167.8c-23.6-24-56.6-37.6-90.4-37.6H832V157.2c0-51.4-44.4-93.2-98.8-93.2H98.8C44.4 64 0 105.8 0 157.2v581.6C0 790.2 44.4 832 98.8 832h32.4c-2.2 10.4-3.2 21-3.2 32 0 88.4 71.6 160 160 160s160-71.6 160-160c0-11-1.2-21.6-3.2-32h390.4c-2.2 10.4-3.2 21-3.2 32 0 88.4 71.6 160 160 160s160-71.6 160-160c0-11-1.2-21.6-3.2-32H1264c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM920.2 320c16.8 0 33.4 6.8 45.2 18.8l167.8 167.8c1.6 1.6 2.2 3.8 3.6 5.6H832v-192h88.2zM288 960c-53 0-96-43-96-96s43-96 96-96 96 43 96 96-43 96-96 96z m127.2-192C386 729.4 340 704 288 704s-98 25.4-127.2 64h-62c-19.2 0-34.8-13-34.8-29.2V157.2C64 141 79.6 128 98.8 128h634.4c19.2 0 34.8 13 34.8 29.2V768H415.2zM992 960c-53 0-96-43-96-96s43-96 96-96 96 43 96 96-43 96-96 96z m0-256c-52.2 0-98 25.4-127.2 64H832v-192h320v192h-32.8c-29.2-38.6-75-64-127.2-64z"
      fill={color}
      p-id="23578"
    ></path>
  </svg>
);

export default Truck;
