// 1
import col1 from "../image/col1.jpg";
import col1_1 from "../image/col1-1.jpg";

// 2
import col2 from "../image/col2.jpg";
import col2_1 from "../image/col2-1.jpg";
import col2_2 from "../image/col2-2.jpg";
import col2_3 from "../image/col2-3.jpg";

// 3
import col3 from "../image/col3.jpg";

// 4
import col4 from "../image/col4.jpg";

// 5
import col5 from "../image/col5.jpg";

export const productList = [
  {
    id: 1,
    title: "Custom July Mega Box",

    description: [
      "The July Mega Box is here and it’s better than ever! You can now add a 1.75kg custom ultimate pouch to your box. It’s worth £20.99 and you get to choose 35 different fillings.This gigantic box is bursting with goodies. With sweets, chocolate and exclusive drinks, the Mega Box has it all. Whether you’re looking for the perfect Birthday gift, or wanting to stock up the treat cupboard, the July Mega Box has you covered. They are limited edition, so buy it while you can! The July Mega Box contains:",
      "1.75kg Custom Ultimate Pouch",
      "1kg Sweetzone Party Mix",
      "Fanta Peach Can",
      "Chupa Chups Melon and Cream Can",
      "Kit Kat Chunky Biscoff White Chocolate Bar",
      "Kinder Country Bar",
      "Bazooka Sour Chewz Mango Chew Bars",
      "Little Rolo Milk Chocolate Sharing Bag",
      "Swizzels Love Hearts Sherbet",
      "Bonds Strawberry Sour Laces",
      "Mentos Rainbow Chewy Dragees",
      "Swizzels Refreshers Cherry Cola Flavour Chew Bar",
      "Bazooka Push Pop Dipperz",
      "Zed Candy Screamers Mega Sour Cherry Gum Pack",
      "Tango Shockers Apple Chew Bars",
      "Bazooka Rattlerz Fruity Bag",
      "Zed Candy Raspberry & Vanilla Ice Cream Jawbreaker 6 Pack",
      "Galaxy Smooth Caramel & Milk Chocolate Snack Bar",
      "Haribo Football Frenzy Limited Edition Bag",
      "July Box",
    ],
    quantity: 1,
    image: [col1, col1_1],
    price: "29.85",
    total: "29.85",
  },

  {
    id: 2,
    title: "Custom Mega Share Picknmix – 1KG – 20 Fillings",
    description: [
      "Building your own pick and mix pouch has never been easier. Here at Sweetzy you can pick exactly what you want and build that custom pouch. If it’s getting a treat for yourself or buying a gift we have you covered. Order sweets with us and have them delivered in the post as early as the next day.",
      "We have over 80 different sweet selections and 5 different pouch sizes. Just to let you know, our ultimate pick and mix pouches are huge",
      "Each filling is approx 50 grams and the total weight will be the target weight or over.",
      "Due to our sweets being close in proximity, some sweets may be mixed. This is a rare occurrence, but we cannot guarantee that some sweets in your selection will not be mixed with others",
      "Ingredients info: Check out our full ingredients list here. We advise you to contact us if you have any questions in relation to allergens or ingredients.",
      "Make sure to check out some of our other treats, including sweet boxes, American candy, and fudge!",
    ],
    quantity: 1,
    image: [col2, col2_1, col2_2, col2_3],
    price: "13.99",
    total: "13.99",
  },
  {
    id: 3,
    title: "Custom Summer Mega Share Bundle",
    description: [
      "We love saving you money! And you will do just that with this Mega Share Bundle for just £15 with the contents of the bundle worth £24.50",
      "You will receive:",
      "1 x 1KG custom mega share picknmix",
      "1 x 250g Fizzy picknmix",
      "1 x 250g Fizzless Picknmix",
      "1 x International snack",
      "CODES CAN NOT BE USED ON THIS PRODUCT DUE TO THE SAVING ALREADY",
      "We have over 80 different sweet selections and 5 different pouch sizes. Just to let you know, our ultimate pick and mix pouches are huge",
      "Each filling is approx 50 grams and the total weight will be the target weight or over.",
      "Due to our sweets being close in proximity, some sweets may be mixed. This is a rare occurrence, but we cannot guarantee that some sweets in your selection will not be mixed with others",
      "Ingredients info: Check out our full ingredients list here. We advise you to contact us if you have any questions in relation to allergens or ingredients.",
      "Make sure to check out some of our other treats, including sweet boxes, American candy, and fudge!",
    ],
    quantity: 1,
    image: [col3, col3],
    price: "15.00",
    total: "15.00",
  },
  {
    id: 4,
    title: "Custom Summer Oh My Gosh Bundle",
    description: [
      "We love saving you money! And you will do just that with this Mega Share Bundle for just £28 with the contents of the bundle worth £41.97",
      "You will receive:",
      "1 x 2.75KG custom Oh My Gosh picknmix",
      "1 x 250g Fizzy picknmix",
      "1 x 250g Fizzless Picknmix",
      "1 x International snack",
      "CODES CAN NOT BE USED ON THIS PRODUCT DUE TO THE SAVING ALREADY",
      "We have over 80 different sweet selections and 5 different pouch sizes. Just to let you know, our ultimate pick and mix pouches are huge",
      "Each filling is approx 50 grams and the total weight will be the target weight or over.",
      "Due to our sweets being close in proximity, some sweets may be mixed. This is a rare occurrence, but we cannot guarantee that some sweets in your selection will not be mixed with others",
      "Ingredients info: Check out our full ingredients list here. We advise you to contact us if you have any questions in relation to allergens or ingredients.",
      "Make sure to check out some of our other treats, including sweet boxes, American candy, and fudge!",
    ],
    quantity: 1,
    image: [col4, col4],
    price: "28.00",
    total: "28.00",
  },
  {
    id: 5,
    title: "Custom Summer Ultimate Bundle",
    description: [
      "We love saving you money! And you will do just that with this Mega Share Bundle for just £20 with the contents of the bundle worth £31.97",
      "You will receive:",
      "1 x 1.75KG custom Ultimate picknmix",
      "1 x 250g Fizzy picknmix",
      "1 x 250g Fizzless Picknmix",
      "1 x International snack",
      "CODES CAN NOT BE USED ON THIS PRODUCT DUE TO THE SAVING ALREADY",
      "We have over 80 different sweet selections and 5 different pouch sizes. Just to let you know, our ultimate pick and mix pouches are huge",
      "Each filling is approx 50 grams and the total weight will be the target weight or over.",
      "Due to our sweets being close in proximity, some sweets may be mixed. This is a rare occurrence, but we cannot guarantee that some sweets in your selection will not be mixed with others",
      "Ingredients info: Check out our full ingredients list here. We advise you to contact us if you have any questions in relation to allergens or ingredients.",
      "Make sure to check out some of our other treats, including sweet boxes, American candy, and fudge!",
    ],
    quantity: 1,
    image: [col5, col5],
    price: "20.00",
    total: "20.00",
  },
];

export const getProductDetailById = (id) => {
  for (let i = 0; i < productList.length; i++) {
    if (productList[i].id === id) {
      return productList[i];
    }
  }
};
