import React from "react";
const TikTok = ({
  viewBox = "0 0 24 24",
  color = "#000",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="2451"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M707.49101 350.108954v345.415805c0 153.508654-99.92142 285.343491-241.739865 319.816807-193.35776 46.248855-356.935353-100.34807-377.585211-275.615872-21.673818-175.097142 84.818011-328.605796 248.224944-363.079113 31.913417-6.826399 78.503592-6.826399 104.614569-0.93863V560.618042c-7.509039-1.96259-14.591429-3.92518-22.185797-4.949139a135.333366 135.333366 0 0 0-150.863425 82.684761 148.559515 148.559515 0 0 0 43.262306 173.219883c43.176976 35.411946 91.132431 40.275756 139.941185 16.724678 48.808755-22.697778 74.834402-63.997493 80.466182-120.059298 0.93863-7.850359 0.68264-16.724678 0.68264-25.598997V18.516608c0-18.772598 0.59731-18.516608 18.431278-18.516608h139.941186c10.239599 0 13.994119 1.87726 14.932748 14.676758 7.509039 114.171528 90.279131 211.533048 196.25898 226.295137 11.348889 1.96259 23.721738 2.90122 36.777226 3.83985v180.046281c-83.879381 1.02396-162.126983-25.598997-231.158946-74.749072z"
      fill={color}
      p-id="2452"
    ></path>
  </svg>
);

export default TikTok;
