import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/about.scss";
import aboutUs from "../image/about-us-1.jpg";

class About extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="about-page">
          <div className="title-head">About</div>
          <div className="about-contain">
            <div className="title">About Sweetzy</div>
            <div className="about-tent">
              <div className="left">
                <img src={aboutUs} alt="" />
              </div>
              <div className="right">
                <p>Who, what and why…….</p>
                <p>
                  Sweetzy has evolved over the past 18 months into one of the
                  biggest online picknmix companies in the UK and has plans to
                  become the best but how did it start? The owner William
                  created the company back in January 2019 when trying to get a
                  good picknmix was either impossible or hugely over priced.
                  Initially it started with creating a basic website and selling
                  picknmix out of a spare room in his parents home…very Rock N
                  Roll!
                </p>
                <p>
                  Over the next few months it began to gain traction and the
                  orders increased over time till October 2019 when William
                  realised for a small company they were producing a level of
                  plastic and non recyclable materials which did not sit well
                  with him, so he took the decision to turn the packaging 100%
                  recyclable. Showing people that even small start up companies
                  can try be as green as they can even though it is more
                  expensive due to reyclable materials not as mass produced.
                </p>
                <p>
                  Over the next few months William with help with others created
                  Sweetzy into a bespoke picknmix solutions were customers can
                  create their own full bespoke picknmix pouch in 100%
                  recyclable packaging. Customers can pick exactly what sweets
                  they would like with a always growing sweet range. In the
                  space of 4 months in early 2020 Sweetzy went from 200sqf
                  offices to over 4,000 square foot warehouse due to the
                  popularity of the company.
                </p>
                <p>
                  Sweetzy still have a lot more to give and are only at the
                  start of their journey to offer everyone a cost effective
                  Picknmix product that minimises hurting the planet.
                </p>
              </div>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default About;
