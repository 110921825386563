import React from "react";
const ShopBasket = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#19aaa6",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="10698"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M698.815291 422.131203l-155.143453-232.612848c-7.021927-7.075139-17.603937-14.119579-28.136827-14.119579-10.582009 0-21.168112 3.5345-28.190039 14.119579l-155.140383 232.612848-169.134095 0c-21.169135 0-35.211966 14.066367-35.211966 35.207873l0 10.587126 88.078011 327.756789c7.076162 28.240181 35.261085 52.870138 66.963111 52.870138l458.199677 0c31.696909 0 59.891042-21.141506 66.912969-52.870138l88.128153-327.756789 0-10.587126c0-21.141506-14.092973-35.207873-35.266202-35.207873L698.815291 422.131203zM409.799852 422.131203l105.73516-155.12094 105.736183 155.12094L409.799852 422.131203zM515.535011 704.071504c-38.772049 0-70.474075-31.726585-70.474075-70.473051 0-38.799678 31.701003-70.524217 70.474075-70.524217 38.72293 0 70.474075 31.724539 70.474075 70.524217C586.009086 672.343895 554.257941 704.071504 515.535011 704.071504"
      fill={color}
      p-id="10699"
    ></path>
  </svg>
);


export default ShopBasket;