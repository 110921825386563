import React, { Component } from "react";
import "../style/home.scss";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import { productList } from "../apis/GetProduct";
import ShoppingCart from "../icon/ShoppingCart";
import tastydaily from "../image/tastydaily.svg";
import tastydaily2 from "../image/tastydaily2.svg";
import tastydaily3 from "../image/tastydaily3.svg";
import tastydaily4 from "../image/tastydaily4.svg";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.routePage = this.routePage.bind(this);
    this.hoverChange = this.hoverChange.bind(this);
    this.routeProductDetail = this.routeProductDetail.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    productList.map((item) => {
      item.isHover = false;
    });
    this.setState({ productList });
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      default:
        break;
    }
  }

  hoverChange(index) {
    let { productList } = this.state;
    productList[index].isHover = !productList[index].isHover;
    this.setState({ productList });
  }

  routeProductDetail(id){
    let {history} = this.props;
    history.push(`/ProductDetail/${id}`)
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="home-page">
          <div className="sec1">
            <h1>Sweetzy</h1>
          </div>
          <div className="sec2">
            <div className="title">Bestsellers In June</div>
            <div className="row">
              {productList.map((item, index) => {
                return (
                  <div
                    className="col"
                    key={index}
                    onMouseEnter={() => this.hoverChange(index)}
                    onMouseLeave={() => this.hoverChange(index)}
                    onClick={()=>this.routeProductDetail(item.id)}
                  >
                    <div className="img-box">
                      {item.isHover ? (
                        <img src={item.image[1]} lt="" />
                      ) : (
                        <img src={item.image[0]} lt="" />
                      )}
                    </div>
                    <div className="txt">
                      <div className="title">{item.title}</div>
                      <div className="tool">
                        <div className="price">£{item.price}</div>
                        <div className="cart">
                          <ShoppingCart size={20} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="sec3">
            <div className="col">
              <div className="img">
                <img src={tastydaily} alt="" />
              </div>
              <div className="txt-box">
                <div className="title">Fast Dispatch</div>
                <div className="p">Dispatch your order within 24 hours</div>
              </div>
            </div>
            <div className="col">
              <div className="img">
                <img src={tastydaily2} alt="" />
              </div>
              <div className="txt-box">
                <div className="title">Freshest Products</div>
                <div className="p">Freshly made stock every day</div>
              </div>
            </div>
            <div className="col">
              <div className="img">
                <img src={tastydaily3} alt="" />
              </div>
              <div className="txt-box">
                <div className="title">Friendly Support</div>
                <div className="p">Email us if you have any queries</div>
              </div>
            </div>
            <div className="col">
              <div className="img">
                <img src={tastydaily4} alt="" />
              </div>
              <div className="txt-box">
                <div className="title">Secure Payments</div>
                <div className="p">We use safest payment technologies</div>
              </div>
            </div>
          </div>
          <div className="sec4">
            <h2>Fast dispatch as standard <br /> to get your delivery quick.</h2>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Home;
