import React from "react";

const Fackbook = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#bfbfbf",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="3328"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M756 6.848l0 150.848-89.728 0q-49.152 0-66.272 20.576t-17.152 61.728l0 108 167.424 0-22.272 169.152-145.152 0 0 433.728-174.848 0 0-433.728-145.728 0 0-169.152 145.728 0 0-124.576q0-106.272 59.424-164.864t158.272-58.56q84 0 130.272 6.848z"
      p-id="3329"
      fill={color}
    ></path>
  </svg>
);

export default Fackbook;
