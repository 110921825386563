import React from "react";
const Time = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#333",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="68751"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512 1024c-282.282667 0-512-229.717333-512-512s229.717333-512 512-512a39.424 39.424 0 0 1 0 78.762667A433.664 433.664 0 0 0 78.762667 512 433.749333 433.749333 0 0 0 512 945.237333 433.834667 433.834667 0 0 0 945.237333 512 39.424 39.424 0 1 1 1024 512c0 282.282667-229.717333 512-512 512z"
      fill={color}
      p-id="68752"
    ></path>
    <path
      d="M511.146667 157.525333a39.424 39.424 0 0 0-39.253334 39.424V512a39.424 39.424 0 1 0 78.677334 0V196.949333a39.424 39.424 0 0 0-39.338667-39.424z"
      fill={color}
      p-id="68753"
    ></path>
    <path
      d="M747.52 472.576H511.146667a39.424 39.424 0 0 0 0 78.848h236.373333a39.424 39.424 0 0 0 0-78.848z"
      fill={color}
      p-id="68754"
    ></path>
  </svg>
);


export default Time;