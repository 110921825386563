import React from "react";

const Instagram = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#bfbfbf",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="4407"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M851.428571 814.857143V444.571429h-77.142857q11.428571 36 11.428572 74.857142 0 72-36.571429 132.857143T649.714286 748.571429t-137.142857 35.428571q-112.571429 0-192.571429-77.428571T240 519.428571q0-38.857143 11.428571-74.857142H170.857143v370.285714q0 14.857143 10 24.857143T205.714286 849.714286h610.857143q14.285714 0 24.571428-10t10.285714-24.857143z m-162.285714-304.571429q0-70.857143-51.714286-120.857143T512.571429 339.428571q-72.571429 0-124.285715 50T336.571429 510.285714t51.714285 120.857143T512.571429 681.142857q73.142857 0 124.857142-50T689.142857 510.285714z m162.285714-205.714285V210.285714q0-16-11.428571-27.714285t-28-11.714286h-99.428571q-16.571429 0-28 11.714286t-11.428572 27.714285v94.285715q0 16.571429 11.428572 28t28 11.428571h99.428571q16.571429 0 28-11.428571t11.428571-28z m99.428572-118.857143v652.571428q0 46.285714-33.142857 79.428572t-79.428572 33.142857H185.714286q-46.285714 0-79.428572-33.142857t-33.142857-79.428572V185.714286q0-46.285714 33.142857-79.428572t79.428572-33.142857h652.571428q46.285714 0 79.428572 33.142857t33.142857 79.428572z"
      p-id="4408"
      fill={color}
    ></path>
  </svg>
);

export default Instagram;
